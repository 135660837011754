import '../components/projects-grid.less'
import Layout from '../components/layout'
import ProjectCard from '../components/project-card'
import React from 'react'
import get from 'lodash/get'
import { SEO } from '../components/seo'
import { graphql } from 'gatsby'

class ProjectsListTemplate extends React.Component {
    render() {
        // Getting array of all projects
        const siteMetadata = get(this.props, 'data.siteMetadata')
        const projects = get(this.props, 'data.projects.edges', [])

        return (
            <Layout>
                <SEO siteMetadata={siteMetadata} />

                <div className="projects_grid">
                    {projects.map(project => (
                        <ProjectCard
                            key={project.node.slug}
                            {...project.node}
                        />
                    ))}
                </div>
            </Layout>
        )
    }
}

export default ProjectsListTemplate

export const pageQuery = graphql`
    query {
        siteMetadata: cmsSiteMetadata {
            title
            description
            image {
                publicURL
            }
            twitterCreator
        }
        projects: allCmsProject(limit: 100) {
            edges {
                node {
                    id
                    defaultTitle
                    cardTitle
                    slug
                    themeColor
                    themeVariant
                    externalUrl
                    cardImage {
                        childImageSharp {
                            gatsbyImageData(width: 320)
                        }
                    }
                    heroSubtitle
                }
            }
        }
    }
`
